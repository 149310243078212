import {
  kernelRegistryAbi,
  kernelTokenAbi,
  dappRegistryAbi,
  contractRegistryAbi,
  krnlFaucetAbi,
} from '@/lib/abi';

export const tokenContractConfig = {
  address: process.env.NEXT_PUBLIC_STAKE_CONTRACT_ADDRESS as `0x${string}`,
  abi: kernelTokenAbi,
};

export const kernelRegistryContractConfig = {
  address: process.env
    .NEXT_PUBLIC_KERNEL_REGISTRY_CONTRACT_ADDRESS as `0x${string}`,
  abi: kernelRegistryAbi,
};

export const contractRegistryContractConfig = {
  address: process.env
    .NEXT_PUBLIC_CONTRACT_REGISTRY_CONTRACT_ADDRESS as `0x${string}`,
  abi: contractRegistryAbi,
};

export const dappRegistryContractConfig = {
  address: process.env
    .NEXT_PUBLIC_DAPP_REGISTRY_CONTRACT_ADDRESS as `0x${string}`,
  abi: dappRegistryAbi,
};

export const faucetContractConfig = {
  address: process.env.NEXT_PUBLIC_FAUCET_CONTRACT_ADDRESS as `0x${string}`,
  abi: krnlFaucetAbi,
};
