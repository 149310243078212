//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ContractRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const contractRegistryAbi = [
  {
    type: 'constructor',
    inputs: [
      {
        name: '_kernelRegistryAddress',
        internalType: 'address',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'MAX_KERNELS_PER_CONTRACT',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'contractAddressToIds',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'contracts',
    outputs: [
      { name: 'contractId', internalType: 'uint256', type: 'uint256' },
      {
        name: 'smartContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: 'chainId', internalType: 'uint64', type: 'uint64' },
      { name: 'tokenAuthorityProvider', internalType: 'uint8', type: 'uint8' },
      {
        name: 'tokenAuthorityEndpoint',
        internalType: 'string',
        type: 'string',
      },
      {
        name: 'tokenAuthorityContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: 'contractOwner', internalType: 'address', type: 'address' },
      { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_contractId', internalType: 'uint256', type: 'uint256' }],
    name: 'getContract',
    outputs: [
      {
        name: '',
        internalType: 'struct ContractRegistry.ContractProperties',
        type: 'tuple',
        components: [
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'smartContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'tokenAuthorityProvider',
            internalType: 'uint8',
            type: 'uint8',
          },
          {
            name: 'tokenAuthorityEndpoint',
            internalType: 'string',
            type: 'string',
          },
          {
            name: 'tokenAuthorityContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'contractOwner', internalType: 'address', type: 'address' },
          { name: 'kernelIds', internalType: 'uint256[]', type: 'uint256[]' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getContracts',
    outputs: [
      {
        name: '',
        internalType: 'struct ContractRegistry.ContractProperties[]',
        type: 'tuple[]',
        components: [
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'smartContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'tokenAuthorityProvider',
            internalType: 'uint8',
            type: 'uint8',
          },
          {
            name: 'tokenAuthorityEndpoint',
            internalType: 'string',
            type: 'string',
          },
          {
            name: 'tokenAuthorityContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'contractOwner', internalType: 'address', type: 'address' },
          { name: 'kernelIds', internalType: 'uint256[]', type: 'uint256[]' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      {
        name: '_smartContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getContractsByContractAddress',
    outputs: [
      {
        name: '',
        internalType: 'struct ContractRegistry.ContractProperties[]',
        type: 'tuple[]',
        components: [
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'smartContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'tokenAuthorityProvider',
            internalType: 'uint8',
            type: 'uint8',
          },
          {
            name: 'tokenAuthorityEndpoint',
            internalType: 'string',
            type: 'string',
          },
          {
            name: 'tokenAuthorityContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'contractOwner', internalType: 'address', type: 'address' },
          { name: 'kernelIds', internalType: 'uint256[]', type: 'uint256[]' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_contractOwner', internalType: 'address', type: 'address' },
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getContractsByOwner',
    outputs: [
      {
        name: '',
        internalType: 'struct ContractRegistry.ContractProperties[]',
        type: 'tuple[]',
        components: [
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'smartContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'tokenAuthorityProvider',
            internalType: 'uint8',
            type: 'uint8',
          },
          {
            name: 'tokenAuthorityEndpoint',
            internalType: 'string',
            type: 'string',
          },
          {
            name: 'tokenAuthorityContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'contractOwner', internalType: 'address', type: 'address' },
          { name: 'kernelIds', internalType: 'uint256[]', type: 'uint256[]' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_contractId', internalType: 'uint256', type: 'uint256' }],
    name: 'getMetadata',
    outputs: [
      {
        name: '',
        internalType: 'struct ContractRegistry.Metadata',
        type: 'tuple',
        components: [
          {
            name: 'kernels',
            internalType: 'struct KernelRegistry.Kernel[]',
            type: 'tuple[]',
            components: [
              { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
              { name: 'resolverType', internalType: 'uint8', type: 'uint8' },
              { name: 'chainId', internalType: 'uint64', type: 'uint64' },
              {
                name: 'kernelContractAddress',
                internalType: 'address',
                type: 'address',
              },
              {
                name: 'functionSignature',
                internalType: 'string',
                type: 'string',
              },
              {
                name: 'functionReturnType',
                internalType: 'string',
                type: 'string',
              },
              { name: 'schemaCid', internalType: 'bytes', type: 'bytes' },
              { name: 'metadataCid', internalType: 'bytes', type: 'bytes' },
              { name: 'isActive', internalType: 'bool', type: 'bool' },
              {
                name: 'deactivatedAfter',
                internalType: 'uint256',
                type: 'uint256',
              },
              { name: 'fee', internalType: 'uint256', type: 'uint256' },
              {
                name: 'stakedBalance',
                internalType: 'uint256',
                type: 'uint256',
              },
              { name: 'kernelOwner', internalType: 'address', type: 'address' },
              { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
            ],
          },
          {
            name: 'contractProperty',
            internalType: 'struct ContractRegistry.ContractProperties',
            type: 'tuple',
            components: [
              { name: 'contractId', internalType: 'uint256', type: 'uint256' },
              {
                name: 'smartContractAddress',
                internalType: 'address',
                type: 'address',
              },
              { name: 'chainId', internalType: 'uint64', type: 'uint64' },
              {
                name: 'tokenAuthorityProvider',
                internalType: 'uint8',
                type: 'uint8',
              },
              {
                name: 'tokenAuthorityEndpoint',
                internalType: 'string',
                type: 'string',
              },
              {
                name: 'tokenAuthorityContractAddress',
                internalType: 'address',
                type: 'address',
              },
              {
                name: 'contractOwner',
                internalType: 'address',
                type: 'address',
              },
              {
                name: 'kernelIds',
                internalType: 'uint256[]',
                type: 'uint256[]',
              },
              { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'contractOwner', internalType: 'address', type: 'address' },
    ],
    name: 'getOwnerContractsCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      {
        name: '_smartContractAddress',
        internalType: 'address',
        type: 'address',
      },
    ],
    name: 'getSmartContractOwnership',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'highestContractId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'ownerContracts',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_chainId', internalType: 'uint64', type: 'uint64' },
      {
        name: '_smartContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: '_tokenAuthorityProvider', internalType: 'uint8', type: 'uint8' },
      {
        name: '_tokenAuthorityEndpoint',
        internalType: 'string',
        type: 'string',
      },
      {
        name: '_tokenAuthorityContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: '_kernelIds', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'registerSmartContract',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      {
        name: '_kernelRegistryAddress',
        internalType: 'address',
        type: 'address',
      },
    ],
    name: 'setKernelRegistryAddress',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: '_contractId', internalType: 'uint256', type: 'uint256' },
      { name: '_kernelIds', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'updateKernelIds',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contractId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'contractOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'ContractPropertiesCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contractId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'prevKernelIds',
        internalType: 'uint256[]',
        type: 'uint256[]',
        indexed: false,
      },
      {
        name: 'newKernelIds',
        internalType: 'uint256[]',
        type: 'uint256[]',
        indexed: false,
      },
    ],
    name: 'KernelIdsUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newKernelRegistryAddress',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'KernelRegistryAddressUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'error',
    inputs: [{ name: 'contractId', internalType: 'uint256', type: 'uint256' }],
    name: 'ContractDoesNotExist',
  },
  {
    type: 'error',
    inputs: [{ name: 'contractId', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidContractId',
  },
  {
    type: 'error',
    inputs: [
      { name: 'kernelRegistry', internalType: 'address', type: 'address' },
    ],
    name: 'InvalidKernelRegistryAddress',
  },
  {
    type: 'error',
    inputs: [{ name: 'length', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidLength',
  },
  {
    type: 'error',
    inputs: [
      { name: 'totalKernelIds', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'InvalidNumberOfKernelIds',
  },
  {
    type: 'error',
    inputs: [
      { name: 'smartContract', internalType: 'address', type: 'address' },
    ],
    name: 'InvalidSmartContractAddress',
  },
  {
    type: 'error',
    inputs: [
      {
        name: 'tokenAuthorityContract',
        internalType: 'address',
        type: 'address',
      },
    ],
    name: 'InvalidTokenAuthorityContractAddress',
  },
  {
    type: 'error',
    inputs: [
      { name: 'offset', internalType: 'uint256', type: 'uint256' },
      { name: 'highestContractId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'OffsetOutOfBounds',
  },
  {
    type: 'error',
    inputs: [
      { name: 'smartContract', internalType: 'address', type: 'address' },
    ],
    name: 'OwnableInterfaceNotImplemented',
  },
  {
    type: 'error',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'OwnableInvalidOwner',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
  },
  {
    type: 'error',
    inputs: [
      { name: 'smartContractOwner', internalType: 'address', type: 'address' },
    ],
    name: 'SmartContractOwnerNotMatch',
  },
  {
    type: 'error',
    inputs: [{ name: 'caller', internalType: 'address', type: 'address' }],
    name: 'UnauthorizedNotContractPropertiesOwner',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DappRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const dappRegistryAbi = [
  { type: 'constructor', inputs: [], stateMutability: 'nonpayable' },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'dapps',
    outputs: [
      { name: 'dappId', internalType: 'uint256', type: 'uint256' },
      { name: 'contractId', internalType: 'uint256', type: 'uint256' },
      { name: 'entryId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'dappOwner', internalType: 'address', type: 'address' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'entries',
    outputs: [
      { name: 'contractId', internalType: 'uint256', type: 'uint256' },
      { name: 'dappOwner', internalType: 'address', type: 'address' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_dappId', internalType: 'uint256', type: 'uint256' }],
    name: 'getDapp',
    outputs: [
      {
        name: '',
        internalType: 'struct DappRegistry.Dapp',
        type: 'tuple',
        components: [
          { name: 'dappId', internalType: 'uint256', type: 'uint256' },
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          { name: 'entryId', internalType: 'bytes32', type: 'bytes32' },
          { name: 'dappOwner', internalType: 'address', type: 'address' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getDapps',
    outputs: [
      {
        name: '',
        internalType: 'struct DappRegistry.Dapp[]',
        type: 'tuple[]',
        components: [
          { name: 'dappId', internalType: 'uint256', type: 'uint256' },
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          { name: 'entryId', internalType: 'bytes32', type: 'bytes32' },
          { name: 'dappOwner', internalType: 'address', type: 'address' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_dappOwner', internalType: 'address', type: 'address' },
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getDappsByOwner',
    outputs: [
      {
        name: '',
        internalType: 'struct DappRegistry.Dapp[]',
        type: 'tuple[]',
        components: [
          { name: 'dappId', internalType: 'uint256', type: 'uint256' },
          { name: 'contractId', internalType: 'uint256', type: 'uint256' },
          { name: 'entryId', internalType: 'bytes32', type: 'bytes32' },
          { name: 'dappOwner', internalType: 'address', type: 'address' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'dappOwner', internalType: 'address', type: 'address' }],
    name: 'getOwnerDappsCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'highestDappId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'ownerDapps',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_contractId', internalType: 'uint256', type: 'uint256' }],
    name: 'registerDapp',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'dappId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'dappOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'DappCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'error',
    inputs: [{ name: 'dappId', internalType: 'uint256', type: 'uint256' }],
    name: 'DappDoesNotExist',
  },
  {
    type: 'error',
    inputs: [{ name: 'dappId', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidDappId',
  },
  {
    type: 'error',
    inputs: [{ name: 'length', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidLength',
  },
  {
    type: 'error',
    inputs: [
      { name: 'offset', internalType: 'uint256', type: 'uint256' },
      { name: 'highestDappId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'OffsetOutOfBounds',
  },
  {
    type: 'error',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'OwnableInvalidOwner',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
  },
  {
    type: 'error',
    inputs: [{ name: 'caller', internalType: 'address', type: 'address' }],
    name: 'UnauthorizedNotDappOwner',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// KernelRegistry
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const kernelRegistryAbi = [
  {
    type: 'constructor',
    inputs: [{ name: '_stakeToken', internalType: 'address', type: 'address' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'FEE_DECIMALS',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'STAKE_DECIMALS',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'WAITING_PERIOD',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'cancelDeactivation',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '_kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'cancelUnstake',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '_kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'completeDeactivation',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '_kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'completeUnstake',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '_kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'getKernel',
    outputs: [
      {
        name: '',
        internalType: 'struct KernelRegistry.Kernel',
        type: 'tuple',
        components: [
          { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
          { name: 'resolverType', internalType: 'uint8', type: 'uint8' },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'kernelContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'functionSignature', internalType: 'string', type: 'string' },
          {
            name: 'functionReturnType',
            internalType: 'string',
            type: 'string',
          },
          { name: 'schemaCid', internalType: 'bytes', type: 'bytes' },
          { name: 'metadataCid', internalType: 'bytes', type: 'bytes' },
          { name: 'isActive', internalType: 'bool', type: 'bool' },
          {
            name: 'deactivatedAfter',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'fee', internalType: 'uint256', type: 'uint256' },
          { name: 'stakedBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'kernelOwner', internalType: 'address', type: 'address' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getKernels',
    outputs: [
      {
        name: '',
        internalType: 'struct KernelRegistry.Kernel[]',
        type: 'tuple[]',
        components: [
          { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
          { name: 'resolverType', internalType: 'uint8', type: 'uint8' },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'kernelContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'functionSignature', internalType: 'string', type: 'string' },
          {
            name: 'functionReturnType',
            internalType: 'string',
            type: 'string',
          },
          { name: 'schemaCid', internalType: 'bytes', type: 'bytes' },
          { name: 'metadataCid', internalType: 'bytes', type: 'bytes' },
          { name: 'isActive', internalType: 'bool', type: 'bool' },
          {
            name: 'deactivatedAfter',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'fee', internalType: 'uint256', type: 'uint256' },
          { name: 'stakedBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'kernelOwner', internalType: 'address', type: 'address' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_kernelOwner', internalType: 'address', type: 'address' },
      { name: '_offset', internalType: 'uint256', type: 'uint256' },
      { name: '_length', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getKernelsByOwner',
    outputs: [
      {
        name: '',
        internalType: 'struct KernelRegistry.Kernel[]',
        type: 'tuple[]',
        components: [
          { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
          { name: 'resolverType', internalType: 'uint8', type: 'uint8' },
          { name: 'chainId', internalType: 'uint64', type: 'uint64' },
          {
            name: 'kernelContractAddress',
            internalType: 'address',
            type: 'address',
          },
          { name: 'functionSignature', internalType: 'string', type: 'string' },
          {
            name: 'functionReturnType',
            internalType: 'string',
            type: 'string',
          },
          { name: 'schemaCid', internalType: 'bytes', type: 'bytes' },
          { name: 'metadataCid', internalType: 'bytes', type: 'bytes' },
          { name: 'isActive', internalType: 'bool', type: 'bool' },
          {
            name: 'deactivatedAfter',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'fee', internalType: 'uint256', type: 'uint256' },
          { name: 'stakedBalance', internalType: 'uint256', type: 'uint256' },
          { name: 'kernelOwner', internalType: 'address', type: 'address' },
          { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'kernelOwner', internalType: 'address', type: 'address' }],
    name: 'getOwnerKernelsCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'highestKernelId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'initiateDeactivation',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: '_kernelId', internalType: 'uint256', type: 'uint256' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'initiateUnstake',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'kernels',
    outputs: [
      { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
      { name: 'resolverType', internalType: 'uint8', type: 'uint8' },
      { name: 'chainId', internalType: 'uint64', type: 'uint64' },
      {
        name: 'kernelContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: 'functionSignature', internalType: 'string', type: 'string' },
      { name: 'functionReturnType', internalType: 'string', type: 'string' },
      { name: 'schemaCid', internalType: 'bytes', type: 'bytes' },
      { name: 'metadataCid', internalType: 'bytes', type: 'bytes' },
      { name: 'isActive', internalType: 'bool', type: 'bool' },
      { name: 'deactivatedAfter', internalType: 'uint256', type: 'uint256' },
      { name: 'fee', internalType: 'uint256', type: 'uint256' },
      { name: 'stakedBalance', internalType: 'uint256', type: 'uint256' },
      { name: 'kernelOwner', internalType: 'address', type: 'address' },
      { name: 'createdAt', internalType: 'uint256', type: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'minimumStake',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '', internalType: 'address', type: 'address' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'ownerKernels',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: '_resolverType', internalType: 'uint8', type: 'uint8' },
      { name: '_chainId', internalType: 'uint64', type: 'uint64' },
      {
        name: '_kernelContractAddress',
        internalType: 'address',
        type: 'address',
      },
      { name: '_functionSignature', internalType: 'string', type: 'string' },
      { name: '_functionReturnType', internalType: 'string', type: 'string' },
      { name: '_schemaCid', internalType: 'bytes', type: 'bytes' },
      { name: '_metadataCid', internalType: 'bytes', type: 'bytes' },
      { name: '_fee', internalType: 'uint256', type: 'uint256' },
      { name: '_initialStake', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'registerKernel',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: '_newMinimumStake', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'setMinimumStake',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: '_kernelId', internalType: 'uint256', type: 'uint256' },
      { name: '_amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'stake',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'stakeToken',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'unstakes',
    outputs: [
      { name: 'unstakeAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'unstakedAfter', internalType: 'uint256', type: 'uint256' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'DeactivationCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'DeactivationInitiated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'kernelOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'KernelCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'KernelDeactivated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newMinimumStake',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'MinimumStakeUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Staked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'UnstakeCancelled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'UnstakeInitiated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'kernelId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Unstaked',
  },
  {
    type: 'error',
    inputs: [{ name: 'kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'ActiveKernelRequired',
  },
  {
    type: 'error',
    inputs: [{ name: 'target', internalType: 'address', type: 'address' }],
    name: 'AddressEmptyCode',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'AddressInsufficientBalance',
  },
  { type: 'error', inputs: [], name: 'FailedInnerCall' },
  {
    type: 'error',
    inputs: [{ name: 'kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'InactiveKernelRequired',
  },
  {
    type: 'error',
    inputs: [
      { name: 'caller', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'InsufficientBalance',
  },
  {
    type: 'error',
    inputs: [{ name: 'kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidKernelId',
  },
  {
    type: 'error',
    inputs: [{ name: 'length', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidLength',
  },
  {
    type: 'error',
    inputs: [{ name: 'stakeToken', internalType: 'address', type: 'address' }],
    name: 'InvalidStakeTokenAddress',
  },
  {
    type: 'error',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'InvalidUnstakeAmount',
  },
  {
    type: 'error',
    inputs: [{ name: 'kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'KernelDoesNotExist',
  },
  {
    type: 'error',
    inputs: [
      { name: 'provided', internalType: 'uint256', type: 'uint256' },
      { name: 'required', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'MinimumStakeNotMet',
  },
  {
    type: 'error',
    inputs: [
      { name: 'offset', internalType: 'uint256', type: 'uint256' },
      { name: 'highestKernelId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'OffsetOutOfBounds',
  },
  {
    type: 'error',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'OwnableInvalidOwner',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
  },
  {
    type: 'error',
    inputs: [{ name: 'kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'PendingUnstakeExists',
  },
  {
    type: 'error',
    inputs: [{ name: 'kernelId', internalType: 'uint256', type: 'uint256' }],
    name: 'PendingUnstakeNotFound',
  },
  { type: 'error', inputs: [], name: 'ReentrancyGuardReentrantCall' },
  {
    type: 'error',
    inputs: [{ name: 'token', internalType: 'address', type: 'address' }],
    name: 'SafeERC20FailedOperation',
  },
  {
    type: 'error',
    inputs: [
      { name: 'provided', internalType: 'uint256', type: 'uint256' },
      { name: 'required', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'StakeAllowanceNotMet',
  },
  {
    type: 'error',
    inputs: [
      { name: 'provided', internalType: 'uint256', type: 'uint256' },
      { name: 'required', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'TimeExceeded',
  },
  {
    type: 'error',
    inputs: [{ name: 'caller', internalType: 'address', type: 'address' }],
    name: 'UnauthorizedNotKernelOwner',
  },
  {
    type: 'error',
    inputs: [
      { name: 'provided', internalType: 'uint256', type: 'uint256' },
      { name: 'required', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'WaitingPeriodNotOver',
  },
  {
    type: 'error',
    inputs: [
      { name: 'provided', internalType: 'uint256', type: 'uint256' },
      { name: 'feeDecimals', internalType: 'uint8', type: 'uint8' },
    ],
    name: 'WholeNumberInDecimalsRequired',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// KernelToken
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const kernelTokenAbi = [
  {
    type: 'constructor',
    inputs: [
      { name: 'name', internalType: 'string', type: 'string' },
      { name: 'symbol', internalType: 'string', type: 'string' },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'MINTER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'PAUSER_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'value', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'callerConfirmation', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'value', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'previousAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'newAdminRole',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Unpaused',
  },
  { type: 'error', inputs: [], name: 'AccessControlBadConfirmation' },
  {
    type: 'error',
    inputs: [
      { name: 'account', internalType: 'address', type: 'address' },
      { name: 'neededRole', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'AccessControlUnauthorizedAccount',
  },
  {
    type: 'error',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'allowance', internalType: 'uint256', type: 'uint256' },
      { name: 'needed', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'ERC20InsufficientAllowance',
  },
  {
    type: 'error',
    inputs: [
      { name: 'sender', internalType: 'address', type: 'address' },
      { name: 'balance', internalType: 'uint256', type: 'uint256' },
      { name: 'needed', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'ERC20InsufficientBalance',
  },
  {
    type: 'error',
    inputs: [{ name: 'approver', internalType: 'address', type: 'address' }],
    name: 'ERC20InvalidApprover',
  },
  {
    type: 'error',
    inputs: [{ name: 'receiver', internalType: 'address', type: 'address' }],
    name: 'ERC20InvalidReceiver',
  },
  {
    type: 'error',
    inputs: [{ name: 'sender', internalType: 'address', type: 'address' }],
    name: 'ERC20InvalidSender',
  },
  {
    type: 'error',
    inputs: [{ name: 'spender', internalType: 'address', type: 'address' }],
    name: 'ERC20InvalidSpender',
  },
  { type: 'error', inputs: [], name: 'EnforcedPause' },
  { type: 'error', inputs: [], name: 'ExpectedPause' },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// KrnlFaucet
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const krnlFaucetAbi = [
  {
    type: 'constructor',
    inputs: [
      { name: '_token', internalType: 'contract IERC20', type: 'address' },
      { name: '_faucetAmount', internalType: 'uint256', type: 'uint256' },
      { name: '_cooldownPeriod', internalType: 'uint256', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'cooldownPeriod',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'faucetAmount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'faucetBalance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'lastRequestTime',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'paused',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'requestTokens',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newCooldownPeriod', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'setCooldownPeriod',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'newAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'setFaucetAmount',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newToken', internalType: 'contract IERC20', type: 'address' },
    ],
    name: 'setToken',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'token',
    outputs: [{ name: '', internalType: 'contract IERC20', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'withdrawTokens',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newCooldownPeriod',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'CooldownPeriodUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'FaucetAmountUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Paused',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newToken',
        internalType: 'contract IERC20',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'TokenUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'requester',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'TokensRequested',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'TokensWithdrawn',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'account',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'Unpaused',
  },
  {
    type: 'error',
    inputs: [{ name: 'target', internalType: 'address', type: 'address' }],
    name: 'AddressEmptyCode',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'AddressInsufficientBalance',
  },
  { type: 'error', inputs: [], name: 'AmountMustBeGreaterThanZero' },
  {
    type: 'error',
    inputs: [
      { name: 'lastRequestTime', internalType: 'uint256', type: 'uint256' },
      { name: 'cooldownPeriod', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'CooldownNotElapsed',
  },
  { type: 'error', inputs: [], name: 'EnforcedPause' },
  { type: 'error', inputs: [], name: 'ExpectedPause' },
  { type: 'error', inputs: [], name: 'FailedInnerCall' },
  {
    type: 'error',
    inputs: [
      { name: 'faucetBalance', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'FaucetEmpty',
  },
  { type: 'error', inputs: [], name: 'InvalidTokenAddress' },
  {
    type: 'error',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'OwnableInvalidOwner',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
  },
  { type: 'error', inputs: [], name: 'ReentrancyGuardReentrantCall' },
  {
    type: 'error',
    inputs: [{ name: 'token', internalType: 'address', type: 'address' }],
    name: 'SafeERC20FailedOperation',
  },
  {
    type: 'error',
    inputs: [
      { name: 'requestedAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'availableBalance', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'WithdrawalFailed',
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TokenAuthority
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const tokenAuthorityAbi = [
  {
    type: 'function',
    inputs: [],
    name: 'UPGRADE_INTERFACE_VERSION',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'auth', internalType: 'bytes', type: 'bytes' },
      { name: 'executionPlan', internalType: 'bytes', type: 'bytes' },
      { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getOpinion',
    outputs: [
      { name: '', internalType: 'bool', type: 'bool' },
      { name: '', internalType: 'bool', type: 'bool' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: '_password', internalType: 'bytes', type: 'bytes' }],
    name: 'getSigningKeypairPrivateKey',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'getSigningKeypairPublicKey',
    outputs: [
      { name: '', internalType: 'bytes', type: 'bytes' },
      { name: '', internalType: 'address', type: 'address' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [
      { name: 'initialOwner', internalType: 'address', type: 'address' },
      { name: '_opinionMaker', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'auth', internalType: 'bytes', type: 'bytes' },
      { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'isKernelAllowed',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'entryId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'registerdApp',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'kernelId', internalType: 'uint256', type: 'uint256' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'setKernel',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: '_opinionMaker', internalType: 'address', type: 'address' },
    ],
    name: 'setOpinionMaker',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'runtimeDigest', internalType: 'bytes', type: 'bytes' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'setRuntimeDigest',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'pubKey', internalType: 'bytes', type: 'bytes' },
      { name: 'privKey', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'setSigningKeypair',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'krnlNodePubKey', internalType: 'address', type: 'address' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'setWhitelist',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [{ name: '_password', internalType: 'bytes32', type: 'bytes32' }],
    name: 'setsigningKeypairRetrievalPassword',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'auth', internalType: 'bytes', type: 'bytes' },
      { name: 'executionPlan', internalType: 'bytes', type: 'bytes' },
      { name: 'functionParams', internalType: 'bytes', type: 'bytes' },
      { name: 'kernelParamObjects', internalType: 'bytes', type: 'bytes' },
      { name: 'kernelResponses', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'sign',
    outputs: [
      { name: '', internalType: 'bytes', type: 'bytes' },
      { name: '', internalType: 'bytes32', type: 'bytes32' },
      { name: '', internalType: 'bytes', type: 'bytes' },
    ],
    stateMutability: 'view',
  },
  {
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
  },
  {
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'version',
        internalType: 'uint64',
        type: 'uint64',
        indexed: false,
      },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    type: 'error',
    inputs: [{ name: 'target', internalType: 'address', type: 'address' }],
    name: 'AddressEmptyCode',
  },
  { type: 'error', inputs: [], name: 'ECDSAInvalidSignature' },
  {
    type: 'error',
    inputs: [{ name: 'length', internalType: 'uint256', type: 'uint256' }],
    name: 'ECDSAInvalidSignatureLength',
  },
  {
    type: 'error',
    inputs: [{ name: 's', internalType: 'bytes32', type: 'bytes32' }],
    name: 'ECDSAInvalidSignatureS',
  },
  {
    type: 'error',
    inputs: [
      { name: 'implementation', internalType: 'address', type: 'address' },
    ],
    name: 'ERC1967InvalidImplementation',
  },
  { type: 'error', inputs: [], name: 'ERC1967NonPayable' },
  { type: 'error', inputs: [], name: 'FailedCall' },
  { type: 'error', inputs: [], name: 'InvalidInitialization' },
  { type: 'error', inputs: [], name: 'NotInitializing' },
  {
    type: 'error',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'OwnableInvalidOwner',
  },
  {
    type: 'error',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
  },
  { type: 'error', inputs: [], name: 'UUPSUnauthorizedCallContext' },
  {
    type: 'error',
    inputs: [{ name: 'slot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'UUPSUnsupportedProxiableUUID',
  },
  { type: 'error', inputs: [], name: 'expmod_Error' },
  { type: 'error', inputs: [], name: 'k256Decompress_Invalid_Length_Error' },
  { type: 'error', inputs: [], name: 'k256DeriveY_Invalid_Prefix_Error' },
] as const;
